import { setRemoteDefinitions } from '@nx/angular/mf';

const id = crypto.randomUUID();//cache buster for module federation manifest

const windowHostRoot = window['hostRoot'];
const localTraefik = window.location.hostname ==='standard.traefik.me';
let hostRoot = window.location.origin;
let manifestPath = '/assets/module-federation.manifest.json?id=';
if(localTraefik) {

    manifestPath = '/embarkmfe/standard-host-noss/assets/module-federation.manifest.json?id='
  }

if (windowHostRoot) {
  hostRoot = windowHostRoot;
}

fetch(hostRoot + manifestPath + id)
.then((res) => res.json())
.then((definitions) => {
  setRemoteDefinitions(definitions);
  return definitions;
})
.then((definitions) => import('./bootstrap').then((m) => m.createOneEmbarkApplication(definitions)).catch((err) => console.error(err)));

